import classNames from 'classnames';
import qs from 'qs';
import React, { ComponentType, FC } from 'react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import GenericPagingHeader from './GenericPagingHeader';
import styles from './styles.module.scss';
import TextInputSearch from '../../atoms/input-elements/text-input-search/TextInputSearch.container';

export interface PagingParams {
  offset?: string;
  limit?: string;
  search?: string;
}

type PagingQueryParams = {
  [key in keyof PagingProps]: string;
};

export type PagingProps = {
  offset?: number;
  search?: string;
};

/**
 * This type was added to try to model what happens when a queryParameterPrefix is passed. Since that is more of a runtime
 * thing, this type seems not very useful... Concretely it can't tell you that the only keys available are
 * this.props.queryParameterPrefix + 'offset' (or another PagingParams key)
 */
type PrefixedPagingParams<P extends string> = {
  [K in keyof PagingQueryParams as `${P}${K & string}`]: PagingQueryParams[K];
};

/**
 * Paging Component to wrap around content. This component has no idea about the shape of the content.
 * It just provides buttons with links to changed url parameters (?offset=20). Then on mounting a function is called
 * with these url parameters changeOffset(offset=20) (typically to fetch paginated content)
 */
export type Props = {
  /** The number of items to fetch each time - the limit for pagination */
  itemsPerPage: number;
  /** If the total number of items is known, the button to go to the first/last page can be shown */
  totalItems?: number;
  /** only if totalItems is known */
  showFirstLast?: boolean;
  /** Is used as an alternative to totalItems for the navigational buttons and also for the default Headline */
  currentItems: number;
  /** Additional headline element to display beside the buttons */
  Headline?: ComponentType;
  /** Optional label for the default Headline -> "Showing {label} 1-10" */
  headerLabel?: string;
  /** Flag to enable the search feature */
  searchEnabled?: boolean;
  /** Optional parameter to separate two Pagings that are displayed on the same page */
  queryParameterPrefix?: string;
};

function queryToParameters(query: string, prefix: string) {
  const queryParameter = qs.parse(query, {
    ignoreQueryPrefix: true,
  }) as PrefixedPagingParams<typeof prefix>;

  let offset = Number(queryParameter[prefix + 'offset']);
  offset = Number.isFinite(Number(offset)) ? Math.max(Number(offset), 0) : 0;

  const search = (queryParameter[prefix + 'search'] as string) || '';

  return { offset, search };
}

function offsetToPage(offset: number, itemsPerPage: number): number {
  return Math.ceil(offset / itemsPerPage) + 1;
}

function offsetToUrl(
  pathname: string,
  search: string,
  offset: number,
  queryParameterPrefix: string
): string {
  const queryParameter = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as PrefixedPagingParams<string>;
  const query = {
    ...queryParameter,
    [queryParameterPrefix + 'offset']: offset || undefined,
  };
  return `${pathname}${qs.stringify(query, { addQueryPrefix: true })}`;
}

function searchToUrl(
  pathname: string,
  prevQuery: string,
  search: string,
  queryParameterPrefix: string,
  offset = 0
): string {
  const queryParameter = qs.parse(prevQuery, {
    ignoreQueryPrefix: true,
  }) as PrefixedPagingParams<string>;
  const query = {
    ...queryParameter,
    [queryParameterPrefix + 'search']: search || undefined,
    [queryParameterPrefix + 'offset']: offset || undefined,
  };
  return `${pathname}${qs.stringify(query, { addQueryPrefix: true })}`;
}

export const usePagingParameters = (prefix = '') => {
  const location = useLocation();
  return queryToParameters(location.search, prefix);
};

const Paging: FC<Props> = ({
  itemsPerPage,
  totalItems,
  currentItems,
  showFirstLast,
  searchEnabled,
  Headline,
  headerLabel,
  queryParameterPrefix = '',
  children,
}) => {
  const history = useHistory();
  const location = history.location;

  const pagingParameters = usePagingParameters(queryParameterPrefix);
  const { search } = pagingParameters;
  let { offset } = pagingParameters;

  // restrict paging to multiples of page size
  if (offset % itemsPerPage) {
    offset = Math.floor(offset / itemsPerPage) * itemsPerPage;
    history.replace(
      searchToUrl(
        location.pathname,
        location.search,
        search,
        queryParameterPrefix,
        offset
      )
    );
  }

  const currentPage = offsetToPage(offset, itemsPerPage);
  const totalPages: number | undefined =
    totalItems && Math.ceil(totalItems / itemsPerPage);

  const canGoBackward = currentPage > 1;
  const canGoForward =
    totalItems === undefined
      ? currentItems === undefined
        ? true
        : currentItems === itemsPerPage
      : currentPage < totalPages;

  return (
    <div className={styles.PagingParent}>
      <div className={styles.PagingHeader}>
        {Headline ? (
          <Headline />
        ) : (
          <GenericPagingHeader
            currentItems={currentItems}
            offset={offset}
            totalItems={totalItems}
            itemLabel={headerLabel}
          />
        )}
        <div className={styles.PagePickerContainer}>
          {searchEnabled && (
            <TextInputSearch
              initialValue={search}
              submitSearchQuery={(search) => {
                history.push(
                  searchToUrl(
                    location.pathname,
                    location.search,
                    search,
                    queryParameterPrefix
                  )
                );
              }}
            />
          )}{' '}
        </div>
        <div className={styles.pagePicker}>
          {totalItems > 0 && showFirstLast && (
            <Link
              data-testingIdentifier={'Page first'}
              to={offsetToUrl(
                location.pathname,
                location.search,
                0,
                queryParameterPrefix
              )}
              className={classNames(
                styles.left,
                styles.leftRounded,
                { [styles.active]: canGoBackward },
                { [styles.inactive]: !canGoBackward }
              )}
            >
              <FiChevronsLeft
                size={16}
                className={classNames(
                  styles.pageChevron,
                  { [styles.active]: canGoBackward },
                  { [styles.inactive]: !canGoBackward }
                )}
              />
            </Link>
          )}

          <Link
            data-testingIdentifier={'Page backward'}
            to={offsetToUrl(
              location.pathname,
              location.search,
              Math.max(offset - itemsPerPage, 0),
              queryParameterPrefix
            )}
            className={classNames(
              styles.left,
              { [styles.leftRounded]: !totalItems || !showFirstLast },
              { [styles.active]: canGoBackward },
              { [styles.inactive]: !canGoBackward }
            )}
          >
            <FiChevronLeft
              size={16}
              className={classNames(
                styles.pageChevron,
                { [styles.active]: canGoBackward },
                { [styles.inactive]: !canGoBackward }
              )}
            />
          </Link>

          <span className={styles.info}>Page {currentPage}</span>

          <Link
            data-testingIdentifier={'Page forward'}
            to={offsetToUrl(
              location.pathname,
              location.search,
              offset + itemsPerPage,
              queryParameterPrefix
            )}
            className={classNames(
              styles.right,
              { [styles.rightRounded]: !totalItems || !showFirstLast },
              { [styles.active]: canGoForward },
              { [styles.inactive]: !canGoForward }
            )}
          >
            <FiChevronRight
              size={16}
              className={classNames(
                styles.pageChevron,
                { [styles.active]: canGoForward },
                { [styles.inactive]: !canGoForward }
              )}
            />
          </Link>

          {totalItems > 0 && showFirstLast && (
            <Link
              data-testingIdentifier={'Page last'}
              to={offsetToUrl(
                location.pathname,
                location.search,
                totalItems - (totalItems % itemsPerPage || itemsPerPage),
                queryParameterPrefix
              )}
              className={classNames(
                styles.right,
                styles.rightRounded,
                { [styles.active]: canGoForward },
                { [styles.inactive]: !canGoForward }
              )}
            >
              <FiChevronsRight
                size={16}
                className={classNames(
                  styles.pageChevron,
                  { [styles.active]: canGoForward },
                  { [styles.inactive]: !canGoForward }
                )}
              />
            </Link>
          )}
        </div>
      </div>
      <div className={styles.PagingContent}>{children}</div>
    </div>
  );
};

export default Paging;
