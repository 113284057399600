import React, { DragEvent, FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './contentElement.scss';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Dispatch } from 'redux-act';

import { setOpenContextMenu } from '../../../../redux/workbench/modules/content.module';
import { DeprecatedRootState } from '../../../../store/state.type';

import { useDispatch, useSelector } from 'react-redux';

import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';
import { useSelectedDirPath } from '../../../workbench/hooks';
import {
  ContentElementType,
  OnClickListener as FBOnClickListener,
} from '../generic-file-browser/GenericFileBrowser';

import _ from 'lodash';
import { formatFileSizeForOS } from 'common/dist/utils/storage';

/**
 * Derive the context menu id (based on the path of the file / directory)
 * @param path
 */
function menuId(path: string): string {
  return `cm-nb-${path.replace('/', '').toLowerCase()}`;
}

/**
 * Returns a list of additional class names depending on the type of the entry
 * @param type
 */
function getAdditionalClassNames(type: ContentElementType): string[] {
  switch (type) {
    case ContentElementType.FILE_NOTEBOOK:
    case ContentElementType.FILE_CODE:
    case ContentElementType.FILE_DEFAULT:
    case ContentElementType.FILE_TEXT:
    case ContentElementType.FILE_MARKDOWN:
    case ContentElementType.FILE_CONFIG:
    case ContentElementType.FILE_GITIGNORE:
    case ContentElementType.FILE_AUGUR_SETTINGS:
      return ['ContentElement--file'];
    default:
      return [];
  }
}

function shouldRenderSize(type: ContentElementType): boolean {
  return ![
    ContentElementType.DIRECTORY_REPOSITORY,
    ContentElementType.DIRECTORY_PLAIN,
    ContentElementType.SPECIAL_ONE_DIRECTORY_UP,
    ContentElementType.SPECIAL_LAUNCHER,
    ContentElementType.RECYCLE_BIN,
  ].includes(type);
}

function displayName(name: string, type: ContentElementType): string {
  switch (type) {
    case ContentElementType.DIRECTORY_REPOSITORY:
      return name.replace(/\.asr$/, '');
    case ContentElementType.RECYCLE_BIN:
      return 'Recycle Bin';
    default:
      return name;
  }
}

const ContentElement: FC<ContentElementProps> = (props) => {
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const dispatch = useDispatch();
  const { name, path, linkTo, ContextMenu, type, isContextMenuExpanded } =
    props;
  const [highlighted, setHighlighted] = useState(false);

  const selectedDirPath = useSelectedDirPath();

  const renderInner = () => {
    const {
      name,
      isDraggable,
      onDragStart,
      onDragEnter,
      onDragOver,
      onDragLeave,
      onDrop,
      Icon,
      size,
      width,
      ContextMenu,
      linkTo,
      onClick,
      type,
    } = props;
    const additionalClassNames = getAdditionalClassNames(type);

    return (
      <div
        draggable={isDraggable}
        onDragStart={(e) => {
          onDragStart &&
            onDragStart(state, dispatch, path, name, setHighlighted)(e);
        }}
        onDragEnter={(e) => {
          onDragEnter &&
            onDragEnter(state, dispatch, path, name, setHighlighted)(e);
        }}
        onDragOver={(e) => {
          onDragOver &&
            onDragOver(state, dispatch, path, name, setHighlighted)(e);
        }}
        onDragLeave={(e) => {
          onDragLeave &&
            onDragLeave(state, dispatch, path, name, setHighlighted)(e);
        }}
        onDrop={(e) => {
          onDrop && onDrop(state, dispatch, path, name, setHighlighted)(e);
        }}
        className={
          `ContentElement ${additionalClassNames.join(' ')}` +
          (highlighted ? ' ContentElement--highlighted' : '') +
          (onClick || linkTo ? ' ContentElement--clickable' : '')
        }
      >
        <Icon className={'ContentElement--icon'} size={'15px'} />
        <p className={'ContentElement--name'}>{displayName(name, type)}</p>

        {width !== undefined &&
          size !== undefined &&
          width > 300 &&
          shouldRenderSize(type) && (
            <p className={'ContentElement--size'}>
              {formatFileSizeForOS(size)}
            </p>
          )}

        {ContextMenu && (
          <div
            data-testingidentifier={'context-menu-parent'}
            className={
              isContextMenuExpanded
                ? 'context-menu-parent context-menu-parent--active'
                : 'context-menu-parent'
            }
            onClick={(e) => {
              if (!isContextMenuExpanded) {
                // Context menu is not opened: Open it
                dispatch(setOpenContextMenu(path));
              } else {
                // Context menu is opened: Close it
                dispatch(setOpenContextMenu(undefined));
              }

              e.stopPropagation();
            }}
          >
            <FiMoreHorizontal size={17} />
          </div>
        )}
      </div>
    );
  };

  /**
   * Renders the ContentElement with a Link component (so that clicking the element jumps to a certain URL)
   */
  const renderWithLink = () => {
    const { linkTo, onClick, history, path, name, type } = props;
    return (
      <LinkWithQuery
        to={linkTo}
        onClick={(e) => {
          onClick &&
            onClick(
              state,
              dispatch,
              history,
              selectedDirPath,
              _.pick(props, ['size', 'name', 'path', 'type'])
            );
        }}
        style={{ textDecoration: 'none' }}
      >
        {renderInner()}
      </LinkWithQuery>
    );
  };

  /**
   * Renders the ContentElement with a simple div component (so that clicking the element does not jump to a certain URL)
   */
  const renderWithoutLink = () => {
    const { onClick, history, path, name, type } = props;
    return (
      <div
        onClick={() => {
          onClick &&
            onClick(
              state,
              dispatch,
              history,
              selectedDirPath,
              _.pick(props, ['size', 'name', 'path', 'type'])
            );
        }}
      >
        {renderInner()}
      </div>
    );
  };

  return (
    <div id={`wb_ce_${name}`}>
      {linkTo ? renderWithLink() : renderWithoutLink()}

      {ContextMenu && isContextMenuExpanded && (
        <ContextMenu
          menuId={menuId(path)}
          name={name}
          path={path}
          type={type}
        />
      )}
    </div>
  );
};

export default withRouter(ContentElement);

export type ContentElementProps = {
  /** Name of the file */
  name: string;
  /** Path of the file */
  path: string;
  /** Type of the file */
  type: ContentElementType;
  isContextMenuExpanded: boolean;
  /** Filesize */
  size?: number;
  /** Width of file browser */
  width?: number;
  /** Is the element draggable? (for example to move a file) */
  isDraggable?: boolean;
  /** Optional link that is called when clicking the element */
  linkTo?: string;
  /** React DnD callbacks */
  onDragStart?: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string,
    setHighlighted: (val: boolean) => void
  ) => (e?: DragEvent<HTMLDivElement>) => void;
  onDragEnter?: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string,
    setHighlighted: (val: boolean) => void
  ) => (e?: DragEvent<HTMLDivElement>) => void;
  onDragOver?: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string,
    setHighlighted: (val: boolean) => void
  ) => (e?: DragEvent<HTMLDivElement>) => void;
  onDragLeave?: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string,
    setHighlighted: (val: boolean) => void
  ) => (e?: DragEvent<HTMLDivElement>) => void;
  onDrop?: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string,
    setHighlighted: (val: boolean) => void
  ) => (e?: DragEvent<HTMLDivElement>) => void;
  /** Callback onClick */
  onClick?: FBOnClickListener;
  /** Icon of the element */
  Icon: React.ComponentType<{ className: string; size: string }>;
  /** React component for the Context Menu */
  ContextMenu?: React.ComponentType<{
    menuId: string;
    name: string;
    path: string;
    type: ContentElementType;
  }>;
} & RouteComponentProps;
