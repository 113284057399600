import classNames from 'classnames';
import errorMessages from 'common/dist/messages/error';
import { Augur } from 'common/dist/types/augur';
import { Summary } from 'common/dist/types/habitat';
import { RealtimeSummary as RealtimeSummaryT } from 'common/dist/types/orchestration/realtime';
import React, { FC, Key, useEffect } from 'react';
import { FiPlay, FiStopCircle } from 'react-icons/fi';
import { LiaExternalLinkAltSolid } from 'react-icons/lia';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import {
  activateRealtimeModel,
  deactivateRealtimeModel,
  useRealtimeSummary,
} from '../../../core/api/orchestration';
import { realtimeAugurCodeToRoom, socket } from '../../../socket';
import Button from '../../atoms/button/Button';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../atoms/formatted-date-time/FormattedDateTime';
import { getAugurDetailsLink } from '../../index/routes';
import Paging from '../../molecules/paging/Paging';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import { orchestrationRoutes } from '../routes';

interface Props {
  fetchSummary: (...args: unknown[]) => unknown;
  summary?: Summary;
}

const PAGE_SIZE = 10;

interface RowProps {
  augur: Augur;
  realtimeInfo: RealtimeSummaryT | undefined;
  key: Key;
  notInteractive?: boolean;
}

export const Row: FC<RowProps> = ({
  augur,
  realtimeInfo,
  key,
  notInteractive = false,
}) => {
  const history = useHistory();
  // Only allow starting if there is no pod currently running (as far as we know). We don't want to start while another is terminating for example
  const isStartable = !realtimeInfo;
  const startedDate = realtimeInfo?.started
    ? new Date(realtimeInfo?.started)
    : undefined;
  return (
    <div
      key={key}
      className={classNames(styles.item, styles.row, {
        [styles.notInteractive]: notInteractive,
      })}
      data-testingIdentifier={`RealtimeRow-${augur.name}`}
      onClick={() =>
        !notInteractive &&
        history.push(
          `${orchestrationRoutes.basePath}/${orchestrationRoutes.realtime.path}/${orchestrationRoutes.realtime.details}/${augur.code}`
        )
      }
    >
      <div className={styles.name}>{augur.name}</div>
      <div className={styles.info}>{augur.code}</div>
      <div
        className={styles.info}
        style={{
          gridColumn: '3',
        }}
      >
        {realtimeInfo?.modelCode}
      </div>
      <div
        className={styles.info}
        style={{
          gridColumn: '4',
        }}
      >
        {realtimeInfo?.status}
      </div>
      <div
        className={styles.info}
        style={{
          gridColumn: '5',
        }}
      >
        {startedDate !== undefined ? (
          <>
            <strong className={styles.field}>
              <FormattedRelativeTimeConvenient
                date={startedDate}
                tooltip={false}
              />
            </strong>
            <span className={styles.field}>
              <FormattedDateTime date={startedDate} />
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.buttons}>
        <div
          className={styles.buttonsWrapper}
          onClick={(evt) => evt.stopPropagation()}
        >
          {isStartable ? (
            <Button
              Icon={() => <FiPlay size={18} />}
              form={'squared'}
              onClick={() =>
                activateRealtimeModel(augur.code, 'rest').catch((e) =>
                  console.error(e)
                )
              }
              title={'Start Realtime Prediction'}
            />
          ) : (
            <Button
              Icon={() => <FiStopCircle size={18} />}
              form={'squared'}
              onClick={() =>
                deactivateRealtimeModel(augur.code, 'rest').catch((e) =>
                  console.error(e)
                )
              }
              title={'Stop Realtime Prediction'}
            />
          )}
          <Button
            Icon={() => <LiaExternalLinkAltSolid size={20} />}
            form={'squared'}
            linkTo={getAugurDetailsLink({
              habitatCode: augur.habitatCode,
              augurCode: augur.code,
            })}
            title={'Go To Augur'}
          />
        </div>
      </div>
    </div>
  );
};

const RealtimeSummary: FC<Props> = (props) => {
  const { summary, fetchSummary } = props;

  const realtimeSummary = useRealtimeSummary();
  useEffect(() => {
    socket.emit('subscribe', realtimeAugurCodeToRoom);
    return () => socket.emit('unsubscribe', realtimeAugurCodeToRoom);
  }, []);

  useEffect(() => {
    if (!summary) fetchSummary();
  }, [fetchSummary]);

  const currentItems = summary
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
    .filter((item) => item.augurs.length).length;

  return (
    <ErrorBoundary
      errorHeadline={errorMessages.headline}
      errorDescription={errorMessages.description}
    >
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content not-scrollable'}>
          <Paging
            itemsPerPage={PAGE_SIZE}
            currentItems={currentItems}
            searchEnabled
            headerLabel={'Realtime Predictions'}
          >
            <>
              <div className={styles.tableHeader}>
                <strong>Augur Name</strong>
                <strong>Augur Code</strong>
                <strong>Model Code</strong>
                <strong>Status</strong>
                <strong>Started at</strong>
                <strong>Actions</strong>
              </div>
              {summary.map((habitat) => {
                {
                  return habitat.augurs.map((augur, index) => {
                    const realtimeInfo = realtimeSummary.find?.(
                      (aug) => aug.augurCode === augur.code
                    );
                    return (
                      <Row
                        augur={augur}
                        realtimeInfo={realtimeInfo}
                        key={index}
                      />
                    );
                  });
                }
              })}
            </>
          </Paging>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default RealtimeSummary;
